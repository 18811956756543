import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const lien_contact = "/contact/"

const KdpImage = ({ href }) => (
  <a href={href || lien_contact} alt="Demandez un devis">
    <StaticImage
      className="rounded-lg shadow-lg mt-6"
      src="../../images/CTAExpertPresChezVous.png"
      alt="Demande de devis"
    />
  </a>
)

export default KdpImage
