import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const KdpImage = ({ href }) => (
  <a href={href} alt="Demandez un devis">
    <StaticImage
      className="rounded-lg shadow-lg mt-6"
      src="../../images/CTAOpengroupe.png"
      alt="Demande de devis"
    />
  </a>
)

export default KdpImage