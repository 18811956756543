import React from "react"
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid'

/*
var pages = [
  { name: 'Plombier dépannage', href: '#', current: false },
  { name: 'Auvergne Rhône Alpes', href: '#', current: false },
  { name: 'Rhône', href: '#', current: false },
  { name: 'Albigny-sur-Saône (69250)', href: '#', current: true },
]
*/
const Ariane = ({fil_ariane}) => {

  // Traitement du fil ariane
  var numrows = fil_ariane.length
  var liens = [];
  for (var i = 0; i < numrows; i += 2) {
    if (i<(numrows-2)){
      liens.push({ name: fil_ariane[i], href: fil_ariane[i+1], current: false });
    }else{
      liens.push({ name: fil_ariane[i], href: fil_ariane[i+1], current: true });
    }
  }

  return (
    <div>
      <nav className="hidden bg-white max-w-7xl mx-auto pl-8 py-4 lg:block" aria-label="Breadcrumb">
        <ol className="flex items-center">
          <li>
            <div>
              <a href="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                <span className="sr-only">Accueil</span>
              </a>
            </div>
          </li>
          {liens.map((lien) => (
            <li key={lien.name}>
              <div className="flex items-center">
                <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                <a
                  href={lien.href}
                  className="text-sm font-ligther text-gray-500 hover:text-gray-700"
                  aria-current={lien.current ? 'page' : undefined}
                >
                  {lien.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <nav className="block bg-gray-50 max-w-7xl mx-auto pl-1 py-1 lg:hidden" aria-label="Breadcrumb">
        <ol className="items-center">
          <li>
            <div className="flex items-center">
              <div className="z-10 my-1 mx-2 flex h-6 w-6 items-center justify-center rounded-full bg-sky-500">
                <HomeIcon className="flex-shrink-0 h-5 w-5 text-white" aria-hidden="true" />
              </div>
              <a
                href="/"
                className="text-sm font-ligther text-gray-500 hover:text-gray-700"
              >
                Accueil
              </a>
            </div>
          </li>
          {liens.map((lien) => (
            <li key={lien.name}>
              <div className="flex items-center">
                <span className="relative left-5 -top-5 -ml-px h-6 w-0.5 bg-sky-500" aria-hidden="true" />
                <div className="z-10 my-1 mx-2 flex h-6 w-6 items-center justify-center rounded-full bg-sky-500">
                 <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-white" aria-hidden="true" />
                </div>
                <a
                  href={lien.href}
                  className="text-sm font-ligther text-gray-500 hover:text-gray-700"
                  aria-current={lien.current ? 'page' : undefined}
                >
                  {lien.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  )
}


export default Ariane