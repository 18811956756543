import React from "react";
// import PropTypes from "prop-types";

/*
Usage
<Youtube id="" />
*/
{/* <Youtube id="PBh7EQlg10s" /> */}

const YoutubeEmbed = ({ id }) => (
    <iframe
        title="Youtube"
        className="w-full aspect-[16/9] rounded-lg shadow-lg"
        src={`https://www.youtube.com/embed/${id}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
    ></iframe>
);

// YoutubeEmbed.propTypes = {
//     lien: PropTypes.string.isRequired
// };

export default YoutubeEmbed;